import * as React from "react"

import Header from "./header"
import Footer from "./footer"
import "../scss/main.scss"

const Layout = ({ children, bodyClass, loggedTF }) => {
  return (
    <div className={loggedTF}>
      <Header />
      <main className={bodyClass}>
        {children}
      </main>
      <Footer />
    </div>
  )
}

export default Layout
