import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/pages/amvac-2022.scss"

const calendars = [
  { id: "jlappin", fName: "Jim", lName: "Lappin" },
  { id: "critter", fName: "Cory", lName: "Ritter" },
  { id: "cschultz", fName: "Caleb", lName: "Schultz" },
]

class Amvac2022RetailerSummitInterviews extends React.Component {
  constructor(props) {
    super(props)
    this.state = { calendar: "jlappin" }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    // Replacing &reg; elements
    let regElements = document.querySelectorAll(
      "main h2, main h3, main p, main .btn, .card__desc, .hero__title"
    )
    regElements.forEach(function (el) {
      el.innerHTML = el.innerHTML.replace("®", "<sup>&reg;</sup>")
    })

    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  }

  handleChange(e) {
    this.setState({ calendar: e.target.value })
  }

  render() {
    return (
      <Layout bodyclassName="amvac-2022">
        <Seo
          title="2022 Retailer Summit Interviews"
          description="Schedule a meeting with an AMVAC representative to learn more about our SIMPAS-applied Solutions line of products and prescriptive application."
		  robots="noindex, nofollow"
        />

        <section className="amvac-2022">
          <div className="container text-center flow--thick">
            <div className="container--width-sm flow pb-2">
              <h1>Schedule Your Interview Today</h1>
              <p>
                Thank you for your interest in meeting with an AMVAC®
                representative during one of our AMVAC Technology Summits to
                learn more about our SIMPAS-applied Solutions™ line of products
                and prescriptive application.
              </p>
            </div>
            <div className="dates-block p-4 mb-4 mb-lg-5 mt-0 mt-lg-2 bg-white shadow flow">
              <h2 className="text--default h6">
                Opportunities for 30-minute, face-to-face meetings are available
                on the following days and&nbsp;locations:
              </h2>
              <div className="dates__wrapper d-flex flex-wrap flex-lg-nowrap justify-content-around">
                <div className="date-col px-2 py-3">
                  August 18
                  <a
                    href="https://goo.gl/maps/SNe8qQUAq3XAjH9M6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Columbia,{" "}
                    <span className="d-block">Missouri</span>
                  </a>
                </div>
                <div className="date-col__divider d-none d-sm-block"></div>
                <div className="date-col px-2 py-3">
                  August 25
                  <a
                    href="https://goo.gl/maps/wdk3u64mDjwywnye8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Clear Lake,{" "}
                    <span className="d-block">Iowa</span>
                  </a>
                </div>
                <div className="date-col__divider d-none d-sm-block"></div>
                <div className="date-col px-2 py-3">
                  September 13
                  <a
                    href="https://goo.gl/maps/myo2M3DcUuhxFyqn6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Independence,{" "}
                    <span className="d-block">Iowa</span>
                  </a>
                </div>
                <div className="date-col__divider  d-none d-lg-block"></div>
                <div className="date-col px-2 py-3">
                  September 14
                  <a
                    href="https://g.page/rccasino?share"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Davenport,{" "}
                    <span className="d-block">Iowa</span>
                  </a>
                </div>
                <div className="date-col__divider d-none d-sm-block"></div>
                <div className="date-col px-2 py-3">
                  September 15
                  <a
                    className="text--slimmer"
                    href="https://g.page/hilton-garden-inn-rockford?share"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Rockford, <span className="d-block"></span>
                    Illinois
                  </a>
                </div>
              </div>
            </div>
            <div className="container--width-md">
              <p>
                You will be meeting with either <strong>Jim Lappin,</strong>{" "}
                director, SIMPAS® product portfolio & alliances, or{" "}
                <strong>Cory Ritter,</strong> SIMPAS technologies technical
                representative, or <strong>Caleb Schultz,</strong> retail
                channel precision ag services lead. Please use the interface
                below to schedule your time with them.
              </p>
              <p>
                If you are not available to meet in person on any of these days,
                please <a href="mailto:cyates@archermalmo.com?subject=SIMPAS Interview Request">email
                </a> us, and we will coordinate a remote interview at a convenient
                time.
              </p>
            </div>

            <div className="container--width-sm flow pb-2">
              <h2 className="h6 text--default">Select a calendar</h2>
              <p className="mt-0 text-muted calendar__note">
                You are welcome to schedule an interview with all three AMVAC
                representatives. You'll need to schedule each interviewee
                individually.
              </p>
              <div className="calendar__options row">
                {calendars.map((person, i) => {
                  return (
                    <div className="form-check col" key={person.id}>
                      <label
                        className="form-check-label d-block mb-1"
                        for={person.id}
                      >
                        {person.fName} <br /> {person.lName}
                      </label>
                      <input
                        className="form-check-input float-none ms-0"
                        type="radio"
                        name="calOps"
                        id={person.id}
                        value={person.id}
                        checked={this.state.calendar === person.id}
                        onChange={this.handleChange}
                        aria-current={this.state.calendar === person.id}
                        aria-controls="#calendy-carousel"
                        data-bs-target="#calendy-carousel"
                        data-bs-slide-to={i}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            <div
              id="calendy-carousel"
              className="calendy-wrapper carousel mt-md-0"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {calendars.map(person => {
                  let classes = "carousel-item"
                  this.state.calendar === person.id && (classes += " active")
                  return (
                    <div
                      key={`calendar-${person.id}`}
                      id={`calendar-${person.id}`}
                      className={classes}
                    >
                      <div
                        className="calendly-inline-widget"
                        data-url={`https://calendly.com/${person.id}-simpas/${person.id}-simpas`}
                      ></div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
export default Amvac2022RetailerSummitInterviews
