import * as React from "react"
import get from "lodash/get"
import { graphql, Link, StaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "../scss/layout/navigation.scss"

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isMenuActive: false, secondaryNav: [] }
    this.handleMenuClick = this.handleMenuClick.bind(this)
  }

  handleMenuClick(e) {
    e.preventDefault()
    this.setState(state => ({
      isMenuActive: !state.isMenuActive,
    }))
  }

  componentDidMount() {
    var menuItems = document.querySelectorAll(".nav--has-submenu")
    // const secondaryNav = get(this, 'props.data.secondaryNav.menuItems');

    function closeAllNavItems() {
      menuItems.forEach(function (menuItem) {
        menuItem.classList.remove("open")
        menuItem.querySelector("a").setAttribute("aria-expanded", "true")
      })
    }

    // function closeNavItemClickOutSide(event) {
    //   if (!event.target.matches('.nav__trigger')) {
    //     closeAllNavItems();
    //   }
    // }

    Array.prototype.forEach.call(menuItems, function (el) {
      el.querySelector("a").addEventListener("click", function (event) {
        if (!this.parentNode.classList.contains("open")) {
          closeAllNavItems()
          this.parentNode.classList.add("open")
          this.parentNode
            .querySelector("a")
            .setAttribute("aria-expanded", "true")
          this.parentNode
            .querySelector("a")
            .setAttribute("aria-expanded", "true")
        } else {
          this.parentNode.classList.remove("open")
          this.parentNode
            .querySelector("a")
            .setAttribute("aria-expanded", "false")
          this.parentNode
            .querySelector("a")
            .setAttribute("aria-expanded", "false")
        }
        event.preventDefault()
      })
    })


    // Setting active class on navigation
    const pagePathname = window.location.pathname.replace(/\//g, "");
    
    let navItem, parentNavItem;
    if(pagePathname) {
      // Matching the page pathname w/ id, then add active class
      navItem = document.getElementById('nav--' + pagePathname);

      // If element exists, add active class
      if(navItem) {
        navItem.classList.add('nav--active');
        
        // Check if subnav active page has parent
        parentNavItem = navItem.closest('.nav--has-submenu')
        
        // if true, add active class to parent as well
        if(parentNavItem) {
          parentNavItem.querySelector('.nav__trigger').classList.add('nav--active');
        }
      }
    }
  }

  render() {
    const { isMenuActive } = this.state
    const primaryNav = get(this, "props.primaryNav")
    const secondaryNav = get(this, "props.secondaryNav")

    return (
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col" style={{ position: "relative" }}>
              <Link to="/" className="header__logo-link" aria-label="Simpas Trimble Powered - Home">
                <StaticImage
                  src="../images/header-logo.png"
                  alt="Simpas, Trimble Powered logo"
                  className="header__logo"
                  placeholder="blurred"
                />
              </Link>
            </div>

            <div className="col">
              <button
                type="button"
                className={`menu-btn ${isMenuActive && "menu--open"}`}
                aria-expanded="false"
                data-toggle="menu"
                aria-label="main menu"
                onClick={e => this.handleMenuClick(e)}
                href="#!"
                tabIndex="0"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <nav className="nav-wrapper mobile-dropdown">
                <div className="nav-wrapper__container">
                  {/* Primary Menu */}
                  <ul className="nav--primary">
                    {primaryNav.map(item => {
                      if (item.navigationChildren) {
                        // Building dropdown item list
                        const navChildren = item.navigationChildren
                        let subNavItems = navChildren.map(el => (
                          <li key={el.id}>
                            <Link to={el.linkUrl} id={'nav--' + el.linkUrl.replace(/\//g, "")}>{el.linkTitle}</Link>
                          </li>
                        ))

                        return (
                          <li className="nav--has-submenu" key={item.id}>
                            <Link
                              to={item.linkUrl}
                              className="nav__trigger"
                              aria-expanded="false"
                              id={item.linkTitle.trim().toLowerCase().replace(/ /g,'-')}
                            >
                              {item.linkTitle} <i aria-hidden="true">&#9660;</i>
                            </Link>
                            <ul className="nav__sub">{subNavItems}</ul>
                          </li>
                        )
                      } else {
                        return (
                          <li key={item.id}>
                            <Link
                              to={item.linkUrl}
                              className="nav__trigger"
                              aria-expanded="false"
                              id={'nav--' + item.linkUrl.replace(/\//g, "")}
                            >
                              {item.linkTitle}
                            </Link>
                          </li>
                        )
                      }
                    })}
                  </ul>
                  {/* END - Primary Menu */}

                  {/* Secondary Menu */}
                  <ul className="nav--secondary">
                    {secondaryNav.map((item, index) => {
                      return (
                        <li key={item.id} id={'nav--' + item.linkUrl.replace("/", "") + index}>
                          <Link to={item.linkUrl} key={item.id}>
                            {item.linkTitle}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                  {/* END - Secondary Menu */}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query NavigationQuery {
        primaryNav: contentfulNavigation(
          navigationTitle: { eq: "Primary Nav" }
        ) {
          id
          navigationItems {
            id
            linkTitle
            linkUrl
            newTab
            navigationChildren {
              id
              linkTitle
              linkUrl
              newTab
            }
          }
        }
        secondaryNav: contentfulNavigation(
          navigationTitle: { eq: "Secondary Nav" }
        ) {
          id
          navigationItems {
            linkUrl
            linkTitle
            newTab
            id
          }
        }
      }
    `}
    render={data => (
      <Navigation
        primaryNav={data.primaryNav.navigationItems}
        secondaryNav={data.secondaryNav.navigationItems}
      />
    )}
  />
)
