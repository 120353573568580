import * as React from "react"
import Navigation from "../components/navigation"

const Header = ({ siteTitle }) => (
  <header>
    <Navigation />
  </header>
)

export default Header
